import React from 'react'
import Flappy from './screens/Flappy'

function Game () {
  return (
    <Flappy />
  )
}

export default Game
